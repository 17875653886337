import { PatientStatus } from '@app/models/patient/patient.model';
import {
  AllergyStatus,
  ApplicantStatus,
  AppointmentStatus,
  AvailableMasterweekStatus,
  CaregiverCertificate,
  CaregiverRate,
  DutyStatus,
  GeneralStatus,
  InServiceStatus,
  MasterweekStatus,
  OfficeStatus, PatientPriorityLevel,
  PhysicianStatus,
  POCStatus,
  VacancyStatus,
  ReferenceTableStatus
} from '@app/core/enums';
import { UserStatus } from '@app/models/users/user.ifc';
import { VisitStatus } from '@app/models/visits/visits.model';
import {
  ALLERGY_STATUSES_MAPPER,
  APPLICANT_STATUSES_MAPPER,
  APPOINTMENT_STATUSES_MAPPER,
  ASSIGN_VISIT_STATUSES_MAPPER,
  AUTHORIZATION_STATUSES_MAPPER,
  AVAILABLE_MASTERWEEK_STATUSES_MAPPER,
  BLASTING_STATUSES_MAPPER,
  CAREGIVER_CERTIFICATE_STATUSES_MAPPER,
  CAREGIVER_RATE_STATUSES_MAPPER,
  DUTY_STATUSES_MAPPER,
  IN_SERVICE_STATUSES_MAPPER,
  MASTERWEEK_STATUSES_MAPPER,
  NESTED_BLASTING_STATUSES_MAPPER,
  OFFICES_STATUSES_MAPPER,
  REFERENCE_TABLE_STATUSES_MAPPER,
  PATIENT_STATUSES_MAPPER,
  PAYER_STATUSES_MAPPER,
  PHYSICIAN_STATUSES_MAPPER,
  POC_STATUSES_MAPPER,
  USER_STATUSES_MAPPER,
  VACANCY_STATUSES_MAPPER,
  VISIT_STATUSES_MAPPER
} from '@app/core/constants';
import { StatusesMapper, StatusStyles } from '@app/models/statuses-mapper.model';
import { AuthorizationStatus } from '@app/models/patient/authorizations.model';
import { NestedBlastingStatus } from '@app/models/blasting/blasting.model';
import { AssignVisitStatus } from '@app/models/available-masterweeks/available-masterweeks.model';
import { PayerStatus } from '@app/models/payer/payer.model';
import { BlastingStatus } from "@app/models/blasting/blasting-visit.model";
import { PATIENT_PRIORITY_MAPPER } from "@app/core/constants/status-mappers/patient-priority-mapper.constant";
import { UsersTeamStatus } from '@app/core/enums';
import {
  CAREGIVER_TEAM_STATUSES_MAPPER
} from '@app/core/constants/status-mappers/caregiver-team-statuses-mapper.constant';

export type AllEnums =
  VisitStatus |
  BlastingStatus |
  NestedBlastingStatus |
  AssignVisitStatus |
  AvailableMasterweekStatus |
  PatientStatus |
  AuthorizationStatus |
  MasterweekStatus |
  POCStatus |
  AllergyStatus |
  AppointmentStatus |
  UserStatus |
  CaregiverCertificate |
  CaregiverRate |
  InServiceStatus |
  PhysicianStatus |
  DutyStatus |
  PayerStatus |
  ApplicantStatus |
  VacancyStatus |
  OfficeStatus |
  PatientPriorityLevel |
  ReferenceTableStatus |
  UsersTeamStatus;

export const statusesMap = new Map<any, StatusesMapper>([
  [VisitStatus, VISIT_STATUSES_MAPPER],
  [BlastingStatus, BLASTING_STATUSES_MAPPER],
  [NestedBlastingStatus, NESTED_BLASTING_STATUSES_MAPPER],
  [AssignVisitStatus, ASSIGN_VISIT_STATUSES_MAPPER],
  [AvailableMasterweekStatus, AVAILABLE_MASTERWEEK_STATUSES_MAPPER],
  [PatientStatus, PATIENT_STATUSES_MAPPER],
  [AuthorizationStatus, AUTHORIZATION_STATUSES_MAPPER],
  [MasterweekStatus, MASTERWEEK_STATUSES_MAPPER],
  [POCStatus, POC_STATUSES_MAPPER],
  [AllergyStatus, ALLERGY_STATUSES_MAPPER],
  [AppointmentStatus, APPOINTMENT_STATUSES_MAPPER],
  [UserStatus, USER_STATUSES_MAPPER],
  [CaregiverRate, CAREGIVER_RATE_STATUSES_MAPPER],
  [CaregiverCertificate, CAREGIVER_CERTIFICATE_STATUSES_MAPPER],
  [InServiceStatus, IN_SERVICE_STATUSES_MAPPER],
  [PhysicianStatus, PHYSICIAN_STATUSES_MAPPER],
  [DutyStatus, DUTY_STATUSES_MAPPER],
  [PayerStatus, PAYER_STATUSES_MAPPER],
  [ApplicantStatus, APPLICANT_STATUSES_MAPPER],
  [VacancyStatus, VACANCY_STATUSES_MAPPER],
  [OfficeStatus, OFFICES_STATUSES_MAPPER],
  [ReferenceTableStatus, REFERENCE_TABLE_STATUSES_MAPPER],
  [UsersTeamStatus, CAREGIVER_TEAM_STATUSES_MAPPER],
  [PatientPriorityLevel, PATIENT_PRIORITY_MAPPER],
]);

export const statusesStyles: StatusStyles = {
  [GeneralStatus.AcceptingOffer]: {
    bgColor: '#FFF4E5',
    color: '#FF8F00'
  },
  [GeneralStatus.Active]: {
    bgColor: '#E9F1E7',
    color: '#378D3C'
  },
  [GeneralStatus.Applicant]: {
    bgColor: '#E8EFF7',
    color: '#2564AD'
  },
  [GeneralStatus.Available]: {
    bgColor: '#FFF9E5',
    color: '#FF8F00'
  },
  [GeneralStatus.Cancelled]: {
    bgColor: '#FDEBEA',
    color: '#E53935'
  },
  [GeneralStatus.Closed]: {
    bgColor: '#E9EBF0',
    color: '#848BA5'
  },
  [GeneralStatus.Completed]: {
    bgColor: '#E7F9F8',
    color: '#378D3C'
  },
  [GeneralStatus.Coordinated]: {
    bgColor: '#EEFDF1',
    color: '#378D3C'
  },
  [GeneralStatus.DeclinedOffer]: {
    bgColor: '#FDEBEA',
    color: '#E53935'
  },
  [GeneralStatus.Discharged]: {
    bgColor: '#E8EFF7',
    color: '#2564AD'
  },
  [GeneralStatus.EligibleForRehire]: {
    bgColor: '#EEFDF1',
    color: '#378D3C'
  },
  [GeneralStatus.EnRoute]: {
    bgColor: '#EEFDF1',
    color: '#378D3C'
  },
  [GeneralStatus.Expired]: {
    bgColor: '#FBE7EF',
    color: '#D81B60'
  },
  [GeneralStatus.Hold]: {
    bgColor: '#E9EBF0',
    color: '#848BA5'
  },
  [GeneralStatus.HospitalizedAdmitted]: {
    bgColor: '#FFF4E5',
    color: '#FF8F00'
  },
  [GeneralStatus.HospitalizedObservation]: {
    bgColor: '#FFF4E5',
    color: '#FF8F00'
  },
  [GeneralStatus.Inactive]: {
    bgColor: '#E9EBF0',
    color: '#848BA5'
  },
  [GeneralStatus.InProgress]: {
    bgColor: '#E9F1E7',
    color: '#378D3C'
  },
  [GeneralStatus.MedicalLeave]: {
    bgColor: '#FFF4E5',
    color: '#FF8F00'
  },
  [GeneralStatus.Missed]: {
    bgColor: '#FBE7EF',
    color: '#D81B60'
  },
  [GeneralStatus.NotCompleted]: {
    bgColor: '#E9EBF0',
    color: '#848BA5'
  },
  [GeneralStatus.Offered]: {
    bgColor: '#FFF4E5',
    color: '#FF8F00'
  },
  [GeneralStatus.Open]: {
    bgColor: '#C9F7F5',
    color: '#1AC5BD'
  },
  [GeneralStatus.PendingOffer]: {
    bgColor: '#E8EFF7',
    color: '#2564AD'
  },
  [GeneralStatus.Rejected]: {
    bgColor: '#FBE7EF',
    color: '#D81B60'
  },
  [GeneralStatus.Scheduled]: {
    bgColor: '#EAF5FF',
    color: '#3699FF'
  },
  [GeneralStatus.TerminatedInvoluntary]: {
    bgColor: '#E9EBF0',
    color: '#848BA5'
  },
  [GeneralStatus.TerminatedVoluntary]: {
    bgColor: '#E9EBF0',
    color: '#848BA5'
  },
  [GeneralStatus.Unscheduled]: {
    bgColor: '#E9EBF0',
    color: '#848BA5'
  }
};
