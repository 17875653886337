import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MultiselectFieldComponent } from './multiselect-field.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { MaterialModule } from '@app/material.module';
import { LoadingModule } from '@app/shared/components/loading/loading.module';
import { OptionsScrollDirective } from '@app/shared/fields/autocomplete-field/autocomplete-options-scroll.directive';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    TranslateModule,
    MatSelectModule,
    PipesModule,
    MaterialModule,
    LoadingModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    FormsModule,
    OptionsScrollDirective,
    AngularSvgIconModule,
  ],
  declarations: [
    MultiselectFieldComponent,
  ],
  exports: [
    MultiselectFieldComponent
  ]
})
export class MultiselectFieldModule {
}
